import * as React from 'react';

import SVG from './SVG';

const Twitter = ({ color = '#FFFFFF', width = '38' }) => (
  <SVG width={width} viewBox="0 0 38 30">
    <path
      d="M11.95 30c14.341 0 22.183-11.541 22.183-21.547 0-.329-.007-.656-.023-.98A15.608 15.608 0 0 0 38 3.551c-1.397.604-2.9 1.009-4.478 1.192a7.644 7.644 0 0 0 3.43-4.188A15.938 15.938 0 0 1 32 2.393 7.896 7.896 0 0 0 26.31 0c-4.306 0-7.798 3.392-7.798 7.572 0 .596.068 1.173.202 1.728-6.245-.293-12.077-3.165-16.07-7.914a7.39 7.39 0 0 0-1.056 3.807c0 2.628 1.378 4.947 3.47 6.305a7.913 7.913 0 0 1-3.53-.948l-.002.097c0 3.668 2.687 6.73 6.255 7.425a8.025 8.025 0 0 1-3.52.129c.992 3.009 3.87 5.199 7.282 5.26a15.936 15.936 0 0 1-9.683 3.24c-.629 0-1.249-.033-1.86-.103A22.544 22.544 0 0 0 11.952 30"
      fill={color}
      fillRule="nonzero"
    />
  </SVG>
);

export default Twitter;
