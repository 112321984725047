import * as React from 'react';

import SVG from './SVG';

const Github = ({ color = '#FFFFFF', width = '31' }) => (
  <SVG width={width} viewBox="0 0 31 30">
    <path
      d="M15.455 0C6.92 0 0 6.886 0 15.38c0 6.795 4.428 12.559 10.57 14.592.772.14 1.054-.334 1.054-.74 0-.366-.014-1.579-.022-2.863-4.297.93-5.206-1.813-5.206-1.813-.703-1.776-1.716-2.25-1.716-2.25-1.404-.953.107-.934.107-.934 1.552.107 2.369 1.584 2.369 1.584 1.379 2.35 3.618 1.671 4.497 1.277.14-.991.54-1.671.981-2.054-3.43-.389-7.04-1.708-7.04-7.601 0-1.68.603-3.051 1.59-4.128-.158-.39-.69-1.955.153-4.072 0 0 1.296-.413 4.25 1.577a14.78 14.78 0 0 1 3.868-.517c1.313.006 2.635.177 3.869.52C22.273 5.964 23.57 6.38 23.57 6.38c.846 2.119.314 3.682.155 4.07.99 1.077 1.589 2.447 1.589 4.128 0 5.908-3.615 7.209-7.058 7.59.557.477 1.049 1.411 1.049 2.846 0 2.055-.02 3.713-.02 4.22 0 .41.28.888 1.063.738 6.137-2.037 10.561-7.8 10.561-14.593C30.91 6.886 23.99 0 15.455 0z"
      fill={color}
      fillRule="nonzero"
    />
  </SVG>
);

export default Github;
